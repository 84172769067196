import { Injectable } from '@angular/core';

import { IEcomTrackerListProps, IEcomTrackerProductProps } from './tracker.interface';
import { AppTrackerService } from './tracker.service';
import {
    calcProductDiscountString,
    generateRandomTransactionId,
    transformGtmProductPrice,
    transformSegmentProduct
} from './tracker.transformer';

@Injectable()
export class AppEcomTrackerService {
    constructor(private _tracker: AppTrackerService) {}

    trackListView(props: IEcomTrackerListProps): void {
        if (this._tracker.disabled || !props.products.length) {
            return;
        }

        this._tracker.reset();

        const segmentProducts = props.products.map((product, index) =>
            transformSegmentProduct(product, (props.startingPosition || 0) + index + 1)
        );

        try {
            this._tracker.track('Product List Viewed', {
                list_id: props.listId,
                list_name: props.listName || props.listId,
                category: props.listName,
                products: segmentProducts,

                // Google Analytics Universal
                list: props.listId
            });
        } catch (error) {
            console.log(error);
        }
    }

    trackListClick(props: IEcomTrackerProductProps): void {
        if (this._tracker.disabled || !props?.product?.productId) {
            return;
        }

        this._tracker.reset();

        const segmentProduct = transformSegmentProduct(props.product, props.position);

        try {
            this._tracker.track('Product Clicked', {
                list_id: props.listId,
                list_name: props.listName || props.listId,
                products: [segmentProduct],

                // Algolia
                search_index: 'prod_SB-ACTIVE',
                product_id: props.product.productId,
                position: props.position,
                query_id: props.queryId
            });
        } catch (error) {
            console.log(error);
        }
    }

    trackProductView(props: IEcomTrackerProductProps): void {
        if (this._tracker.disabled || !props?.product?.productId) {
            return;
        }

        this._tracker.reset();

        const segmentProduct = transformSegmentProduct(props.product, props.position);
        const gtmProductPrice = transformGtmProductPrice(props.product);

        try {
            this._tracker.track('Product Viewed', {
                currency: 'SEK',
                value: gtmProductPrice,
                products: [segmentProduct],

                // Algolia
                query_id: props.queryId,
                search_index: 'prod_SB-ACTIVE',
                product_id: props.product.productId
            });
        } catch (error) {
            console.log(error);
        }
    }

    trackProductPurchase(props: IEcomTrackerProductProps): void {
        if (this._tracker.disabled || !props?.product?.productId) {
            return;
        }

        this._tracker.reset();

        const segmentProduct = transformSegmentProduct(props.product, props.position);
        const gtmProductPrice = transformGtmProductPrice(props.product);
        const discount = calcProductDiscountString(props.product);
        const transactionId = generateRandomTransactionId();

        try {
            this._tracker.track('Order Completed', {
                order_id: transactionId,
                total: gtmProductPrice,
                discount,
                currency: 'SEK',
                products: [segmentProduct],

                // Algolia
                search_index: 'prod_SB-ACTIVE',
                query_id: props.queryId
            });
        } catch (error) {
            console.log(error);
        }
    }
}
