import { IProduct } from '@vb/shared/interfaces';

import {
    ITrackingFbProduct,
    ITrackingProductGtm,
    ITrackingProductSegment
} from './tracker.interface';

export function calcProductDiscount(product: IProduct): number {
    return product.isDiscounted ? product.priceOriginal - product.price : 0;
}

export function calcProductDiscountString(product: IProduct): string {
    const discount = calcProductDiscount(product);

    return parseFloat(String(discount)).toFixed(2);
}

export function generateProductName(product: IProduct): string {
    return [product.title, product.subtitle, product.vintage]
        .filter((l) => !!l)
        .join(' ')
        .trim();
}

export function generateRandomTransactionId(): string {
    const chars = '0123456789';

    const uniqueId = Array.from({ length: 5 })
        .map(() => {
            return chars.charAt(Math.floor(Math.random() * chars.length));
        })
        .join('');

    const ts = new Date().getTime();

    return `${ts}_${uniqueId}`;
}

export function transformGtmProductPrice(product: IProduct): string {
    if (!product?.price) {
        return '';
    }

    return parseFloat(String(product?.price)).toFixed(2);
}

export function transformSegmentProduct(
    product: IProduct,
    position?: number
): ITrackingProductSegment {
    const name = generateProductName(product);
    const price = transformGtmProductPrice(product);
    const discount = calcProductDiscountString(product);

    return {
        name,
        product_id: product?.productId,
        sku: product?.productNumber,
        category: product?.category?.level1,
        category2: product?.category?.level2,
        category3: product?.category?.level3,
        brand: product?.producer?.name,
        variant: String(product?.volume),
        affiliation: product?.supplier?.name,
        price,
        discount,
        quantity: 1,
        position
    };
}

export function transformGtmProduct(product: IProduct, index?: number): ITrackingProductGtm {
    const item_name = generateProductName(product);
    const discount = calcProductDiscountString(product);
    const price = transformGtmProductPrice(product);

    return {
        item_name,
        item_id: product?.productId,
        item_category: product?.category?.level1,
        item_category2: product?.category?.level2,
        item_category3: product?.category?.level3,
        item_brand: product?.producer?.name,
        item_variant: String(product?.volume),
        affiliation: product?.supplier?.name,
        price,
        discount,
        quantity: 1,
        index
    };
}

export function transformFbProduct(product: IProduct): ITrackingFbProduct {
    const content_type = 'product';
    const content_name = generateProductName(product);
    const content_category = [
        product?.category?.level1,
        product?.category?.level2,
        product?.category?.level3
    ]
        .filter((l) => !!l)
        .join(' > ')
        .trim();

    return {
        content_type,
        content_name,
        content_category,
        content_ids: [product?.productId],
        products: [
            {
                product_id: product?.productId,
                quantity: 1,
                price: product?.price
            }
        ],
        value: product?.price,
        currency: 'SEK'
    };
}

export function convertIndexProduct(product: IProduct): IProduct {
    const { vintage, alcoholPercentage } = product;

    const convert: IProduct = {
        ...product,
        vintage: '' + vintage,
        alcoholPercentage: parseFloat('' + alcoholPercentage),
        supplier:
            typeof product.supplier === 'string' ? { name: product.supplier } : product.supplier,
        producer:
            typeof product.producer === 'string' ? { name: product.producer } : product.producer
    };

    return convert;
}
